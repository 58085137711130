import UIkit from "uikit";
import 'uikit/dist/js/uikit'
import Icons from 'uikit/dist/js/uikit-icons';


UIkit.use(Icons);
// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
let date = document.querySelector('.date');
let current = new Date();
let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
date.innerText = `${days[current.getDay()]}, ${months[current.getMonth()]} ${current.getDate()}, ${current.getFullYear()}`;
// UIkit.notification('Are We Good');

let imgSliderContainer = document.querySelector('.img-hover');
if (imgSliderContainer) {
// let apd = pageYOffset.bind(this);
// console.log(apd);
let classesArray = ['active-1','active-2','active-3'];
let x = 0;
let imgSlideInterval =  setInterval(()=> {
  
  if (x==3){
    x = 0
  }
    imgSliderContainer.setAttribute("class", `img-hover mt:20px ${classesArray[x]}`);
   x++;
},4000);

imgSliderContainer.addEventListener('mouseenter', ()=>{
  clearInterval(imgSlideInterval)
});

imgSliderContainer.addEventListener('mouseleave', ()=>{
  
  imgSlideInterval =  setInterval(()=> {
    
    if (x==3){
      x = 0
    }
    imgSliderContainer.setAttribute("class", `img-hover mt:20px ${classesArray[x]}`);
    x++;
  },4000);
});
}
